import React from "react";
import styled from "styled-components";

interface Props {
  size: number;
}

export default ( {size}: Props ) => {
  return <Spacer
    style={{
      minWidth: size,
      maxWidth: size
    }}
  />
};

const Spacer = styled.div``;
