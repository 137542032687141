import React from "react";
import styled from "styled-components";
import NavigationLink from "./NavigationLink";

interface Props {
  id?: string;
  href: string;
  label: string;
}

export default ( { id, href, label }: Props ) => {
  return (
    <Wrapper>
      <NavigationItemLink
        id={id}
        href={href}
      >
        {label}
      </NavigationItemLink>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
`;

const NavigationItemLink = styled( NavigationLink )`
  position: relative;
  padding: 10px;
  text-decoration: none;
  color: var(--color-text);
  font-weight: var(--font-weight-bold);
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  text-transform: lowercase;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px auto var(--color-primary);
    outline-offset: 2px;
  }
`;
