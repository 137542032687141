import React, { ReactNode } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

interface Props {
  href: string;
  target?: string;
  rel?: string;
  children: ReactNode;
}

export default ({href, target, rel, ...delegated}: Props) => {
  let linkType;

  if (href.match(/^#/)) {
    linkType = 'hash';
  } else if (href.match(/(^http|^mailto)/i) || target === '_blank') {
    linkType = 'external';
  } else {
    linkType = 'internal';
  }

  if (!target) {
    target = linkType === 'external' ? '_blank' : undefined;
  }
  const safeRel = target === '_blank' ? 'noopener noreferrer' : rel;

  if (linkType === 'internal') {
    return (
      <Link to={href} target={target} rel={safeRel} {...delegated} />
    );
  }

  return (
    <ExternalLink
      href={href}
      target={target}
      rel={safeRel}
      {...delegated}
    />
  );
};

const ExternalLink = styled.a`
  color: var(--color-primary);

  &:focus {
    outline: 2px auto var(--color-primary);
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
