import React from "react";
import styled from "styled-components";
import MaxWidthWrapper from "../MaxWidthWrapper/MaxWidthWrapper";
import { Link } from "gatsby";
import Logo from "../Logo/Logo";

const Footer = () => {
  return (
    <Wrapper
      role={"contentinfo"}
    >
      <InnerWrapper>
        <Left>
          <Top>
            <Logo href={"/"}>
              haase.dev
            </Logo>
            <DesktopOnly>
              <Thanks>blablabla</Thanks>
            </DesktopOnly>
          </Top>
          <DesktopOnly>
            <Copyright>
              blubb...
            </Copyright>
          </DesktopOnly>
        </Left>
        <Right>
          <Column>
            <Heading>
              Foo
            </Heading>
            <TwoColumnChildren>
              <FooterLink key={"link1"} to={"/"}>
                Home
              </FooterLink>
              <FooterLink key={"link2"} to={"/content"}>
                Content
              </FooterLink>
              <FooterLink key={"link3"} to={"/"}>
                Link 3
              </FooterLink>
              <FooterLink key={"link4"} to={"/"}>
                Link 4
              </FooterLink>
              <FooterLink key={"link5"} to={"/"}>
                Link 5
              </FooterLink>
            </TwoColumnChildren>
          </Column>
          <Column>
            <Heading>Links</Heading>
            <OneColumnChildren>
              <FooterLink key={"link6"} to={"/"}>
                Privacy Policy
              </FooterLink>
              <FooterLink key={"link7"} to={"/"}>
                Terms and Conditions
              </FooterLink>
              <FooterLink key={"link8"} to={"/"}>
                Impressum
              </FooterLink>
            </OneColumnChildren>
          </Column>
        </Right>
        <MobileOnly>
          <MobileCopyright>
            blubb...
          </MobileCopyright>
        </MobileOnly>
      </InnerWrapper>
    </Wrapper>
  );
};

const DesktopOnly = styled.span`
  @media (max-width: 725px) {
    display: none;
  }
`;

const MobileOnly = styled.span`
  @media (min-width: 726px) {
    display: none;
  }
`;

const Wrapper = styled.footer`
  position: relative;
  background: var(--color-background-footer);
`;

const InnerWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;

  @media (max-width: 725px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 725px) {
    text-align: center;
    padding-bottom: 32px;
    align-items: center;
  }
`;

const Top = styled.div`
`;

const Copyright = styled.div`
  font-size: 12px;
  color: var(--color-gray-200);
`;

const MobileCopyright = styled( Copyright )`
  text-align: center;
  padding-top: 48px;
`;

const Right = styled.div`
  display: flex;

  @media(max-width: 725px) {
    justify-content: space-around;
  }
`;

const Column = styled.div`
  margin-left: 96px;
  padding-top: 8px;

  @media (max-width: 725px) {
    margin-left: 0;
  }
`;

const FooterLink = styled( Link )`
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  color: var(--color-text);
  text-decoration: none;
`;

const Heading = styled.p`
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-300);
`;

const OneColumnChildren = styled.div`
  display: grid;
  width: 100px;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  padding-top: 12px;
`;

const TwoColumnChildren = styled( OneColumnChildren )`
  width: 200px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`;

const Thanks = styled.div`
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  margin-top: 8px;
`;

export default Footer;
