import React, { ReactNode } from "react";
import styled from "styled-components";
import Link from "../Link/Link";

interface Props {
  href: string;
  children: ReactNode;
}

export default ({children, href, ...delegated}: Props) => {
  return (
    <Wrapper href={href} {...delegated}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Link)`
  color: var(--font-primary);
  font-weight: var(--font-weight-bold);
  font-size: 2rem;
  text-decoration: none;
`;
