import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  type: 'large-title' | 'small-title' | 'section-title';
  children: ReactNode;
}

const Heading = ({type = 'large-title', ...delegated}: Props) => {
  let Component;

  switch (type) {
    case "large-title":
      Component = LargeTitle;
      break;
    case "small-title":
      Component = SmallTitle;
      break;
    case "section-title":
      Component = SectionTitle;
      break;
    default:
      throw new Error( `Unknown heading type: ${type}` );
  }

  return <Component {...delegated} />;
};

const LargeTitle = styled.h1`
  font-size: 2.2rem;
`;

const SmallTitle = styled.h1`
  font-size: 0.8rem;
`;

const SectionTitle = styled.h1`
  font-size: 1.75rem;
  color: var(--color-secondary);
  font-weight: var(--font-weight-medium);
  letter-spacing: 2px;
`;

export default Heading;
