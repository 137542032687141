import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  maxWidth?: number;
  children: ReactNode;
}

const MaxWidthWrapper = styled.div<Props>`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: ${( p ) => p.maxWidth || 1100}px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 725px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default MaxWidthWrapper;
