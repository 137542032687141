import React, { ReactNode } from "react";
import Link from "../Link/Link";

interface Props {
  id?: string;
  href: string,
  children: ReactNode;
}

export default ( { href, ...delegated }: Props ) => {
  return (
    <Link href={href} {...delegated} />
  );
};
