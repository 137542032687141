import * as React from "react"
import { ReactNode } from "react"
import styled from "styled-components";
import MaxWidthWrapper from "../components/MaxWidthWrapper/MaxWidthWrapper";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Breadcrumbs, { CrumbLink } from "../components/Breadcrumbs/Breadcrumbs";
import Heading from "../components/Heading/Heading";
import Paragraph from "../components/Paragraph/Paragraph";

interface Props {
  children: ReactNode;
}

const ContentPage = ( { children }: Props ) => (
  <Wrapper>
    <HeaderWrapper>
      <MaxWidthWrapper>
        <Header type={"default"}/>
      </MaxWidthWrapper>
    </HeaderWrapper>

    <HeroWrapper>
      <Hero>
        <MaxWidthWrapper>
          <Breadcrumbs>
            <CrumbLink to={"/"}>Home</CrumbLink>
            <CrumbLink to={"/content"}>Content</CrumbLink>
            <CrumbLink to={"/"}>Foo!</CrumbLink>
          </Breadcrumbs>
          <Heading type={"large-title"}>Content Page</Heading>
        </MaxWidthWrapper>
      </Hero>
    </HeroWrapper>

    <Children>
      <MaxWidthWrapper>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores ea eaque, harum iusto laudantium magni, nulla pariatur perspiciatis provident quas quibusdam, quidem ratione reprehenderit similique sint sunt veniam voluptate.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores ea eaque, harum iusto laudantium magni, nulla pariatur perspiciatis provident quas quibusdam, quidem ratione reprehenderit similique sint sunt veniam voluptate.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores ea eaque, harum iusto laudantium magni, nulla pariatur perspiciatis provident quas quibusdam, quidem ratione reprehenderit similique sint sunt veniam voluptate.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores ea eaque, harum iusto laudantium magni, nulla pariatur perspiciatis provident quas quibusdam, quidem ratione reprehenderit similique sint sunt veniam voluptate.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores ea eaque, harum iusto laudantium magni, nulla pariatur perspiciatis provident quas quibusdam, quidem ratione reprehenderit similique sint sunt veniam voluptate.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores ea eaque, harum iusto laudantium magni, nulla pariatur perspiciatis provident quas quibusdam, quidem ratione reprehenderit similique sint sunt veniam voluptate.
        </Paragraph>


        {children}
      </MaxWidthWrapper>
    </Children>

    <Footer/>
  </Wrapper>
);

const Wrapper = styled.div`
  background: var(--color-background);
  transition: background 200ms;

  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  z-index: 5;
  top: 0;
  background: var(--color-background);
`;

const HeroWrapper = styled.div`
`;

const Hero = styled.div`
`;

const Children = styled.main`
  flex-grow: 1;
  flex-shrink: 0;

  position: relative;
  z-index: 1;

  @media (max-width: 1024px) {
    max-width: 100vw;
    overflow: hidden;
  }
`;


export default ContentPage;
