import React, { ReactNode } from "react";
import styled from "styled-components";
import { ChevronRight } from "react-feather";
import { Link } from "gatsby";

interface Props {
  children: ReactNode;
}

const Breadcrumbs = ( {children}: Props ) => {
  const childCount = React.Children.count( children );
  const childrenWithCarets = React.Children.map(children, ( child, index ) => [
    child,
    index < childCount - 1 && <Caret size={16} key={index} />,
  ] );

  return (
    <Wrapper>
      {childrenWithCarets}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Caret = styled( ChevronRight )`
  color: var(--color-gray-200);
  pointer-events: none;
  margin: 0 8px;
`;

export const CrumbLink = styled( Link )`
  font-size: 1rem;
  color: var(--color-gray-200);
  text-decoration: none;

  &:hover {
    color: var(--color-text);
  }
`;

export default Breadcrumbs;
