import React from "react";
import styled from "styled-components";
import UnstyledButton from "../UnstyledButton/UnstyledButton";
import { ThemeContext, ThemeSetting } from "../ThemeContext";
import { Moon, Sun } from "react-feather";

export default () => {
  const { colorMode, setColorMode } = React.useContext( ThemeContext );

  if (!colorMode) {
    return null;
  }

  function toggleDarkMode() {
    setColorMode( colorMode === ThemeSetting.DARK ? ThemeSetting.LIGHT : ThemeSetting.DARK );
  }

  return (
    <IconWrapper
      onClick={() => toggleDarkMode()}
    >
      {colorMode === ThemeSetting.DARK ? <Sun/> : <Moon/>}
    </IconWrapper>
  );
};

const IconWrapper = styled( UnstyledButton )`
  color: var(--color-text);
`;
