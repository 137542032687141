import styled from "styled-components";

export default styled.p`
  font-size: 1.1875rem;
  margin-bottom: 32px;

  @media (max-width: 725px) {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
  }
`;
