import React from "react";
import styled from "styled-components";
import NavigationItem from "./NavigationItem";

export default ()  => {
  return (
    <Wrapper>
      <List>
        <ListItem>
          <NavigationItem
            label="Now"
            href="/now"
          />
        </ListItem>
        <ListItem>
          <NavigationItem
            label="Blog"
            href="/"
          />
        </ListItem>
        <ListItem>
          <NavigationItem
            label="Digital Garden"
            href="/content"
          />
        </ListItem>
        <ListItem>
          <NavigationItem
            label="About"
            href="/about"
          />
        </ListItem>
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: inline-block;
`;

const List = styled.ul`
  display: flex;
  list-style: none;
`;

const ListItem = styled.li`
  margin: 10px;
`;
