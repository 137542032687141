import React from "react";
import styled from "styled-components";
import DarkModeToggle from "../DarkModeToggle/DarkModeToggle";
import Navigation from "../Navigation/Navigation";
import Spacer from "../Spacer/Spacer";
import Logo from "../Logo/Logo";

interface Props {
  type: 'hero' | 'default';
}

export default ( {}: Props ) => {
  return (
    <Wrapper>
      <Left>
        <Logo href={"/"}>
          haase.dev
        </Logo>
      </Left>
      <Right>
        <DesktopNavigation>
          <Navigation/>
        </DesktopNavigation>
        <Spacer size={64}/>
        <IconWrapper>
          <DarkModeToggle/>
        </IconWrapper>
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: var(--color-text);
  border-bottom: 4px solid var(--color-background-footer);

`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: baseline;
`;

const Right = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const DesktopNavigation = styled.div`
  @media (max-width: 725px) {
    display: none;
  }
`;

